var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.open, "max-width": "330" },
          on: { "click:outside": _vm.close },
        },
        [
          _c("div", { staticClass: "white pa-7" }, [
            _c("div", { staticClass: "mew-heading-2 mb-10" }, [
              _vm._v("Remove token?"),
            ]),
            _c("div", { staticClass: "full-width" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-center py-1" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c("v-img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: _vm.selectedToken.tokenImg,
                          width: "20",
                          height: "20",
                          contain: "",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.selectedToken.token) + " "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center mt-5" },
                [
                  _c("mew-button", {
                    staticClass: "flex-grow-1 mr-1",
                    staticStyle: { "flex-basis": "0" },
                    attrs: {
                      "btn-size": "large",
                      "btn-style": "outline",
                      title: "Keep token",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  }),
                  _c("mew-button", {
                    staticClass: "flex-grow-1 ml-1",
                    staticStyle: { "flex-basis": "0" },
                    attrs: { "btn-size": "large", title: "Remove Token" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.confirmDelete.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }